/* CustomDateHeader.scss */
.custom-date-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Make it full height to center correctly */
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  min-height: 70px;
  background: inherit;

  .weekday {
    background-color: inherit !important;
    color: #767676;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
  }

  .date {
    background-color: inherit !important;
    color: #212121;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    margin-top: 6px;
    border: none;
  }
}
