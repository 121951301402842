.candidateDetailsContainer {
  margin-top: 30px;
  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
  }
  .profileCard {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
    padding: 0px 30px;
    background-color: #fff;
    margin-top: 16px;

    .personalDetails,
    .technicalSkills {
      width: 45%;
      padding-top: 20px;

      h2 {
        margin: 0px;
        padding: 0px;
        color: #0b52d4;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }

      p {
        margin: 0px;
        padding: 0px;
        color: #333;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        margin-bottom: 20px;

        span {
          color: #8b8b8b;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */
          margin-right: 10px;
        }
      }
    }
    .personalDetails {
      border-right: 1px dashed rgba(11, 82, 212, 0.5);
    }

    .technicalSkills {
      .resume {
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        background: #fff;
        box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
        width: fit-content;
        padding: 4px 10px;
        padding-bottom: 0px;
        background: #e5efff;
        cursor: pointer;
        .resumeIcon {
          margin-right: 10px;
        }
        .resumeLink {
          padding-top: 6px;
        }
      }
    }
  }
}
