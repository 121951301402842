.availability-container {
  max-width: 95%;
  margin: 0 auto;
  margin-left: 3vw;
  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 42px;
  }
  .desc {
    margin-top: 42px;
    color: #212121;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .availability-checkbox {
    display: flex;
    align-items: center;
    gap: 38px;
    margin-top: 41px;
    .item,
    .css-3eaalp-MuiTypography-root {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
