.view-recordings-Container {
  max-width: 95%;
  margin: 0 auto;
  margin-left: 3vw;

  .flag-user {
    text-align: right;

    .flag-icon {
      border-radius: 4px;
      border: 1px solid #d24d2c;
      color: #d24d2c;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */

      & > span {
        margin-left: 10px;
      }
    }

    .unflag {
      color: #999999;
      border: 1px solid #999999;
      margin-right: 30px;
    }
  }
  .flag-desc {
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    background: #e5efff;
    padding: 24px 20px;
    margin-top: 30px;
    .title {
      color: #d24d2c;
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .reason {
      color: #333;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.flag-user-container {
  .first {
    justify-content: flex-end;
  }

  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0 25px;
    margin-top: -10px;
  }

  .radio-button {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 24px;
  }

  .message {
    margin-top: 32px;

    .css-ol79aa-MuiInputBase-root-MuiOutlined {
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      background: #f8f9fb;
      color: #333;
      text-align: center;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 14px 16px;
      outline: none;
    }
  }
  .desc {
    color: #646464;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
  }

  .button-container {
    justify-content: flex-start;

    .submit-button {
      margin-left: 18px;
      border-radius: 8px;
      background: #062b6e;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 14px 24px;
    }
  }
}
