.recurring-container {
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 0.4);
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.04),
    2px 2px 4px 0px rgba(79, 79, 79, 0.04);
  padding: 40px 30px;
  margin-top: 30px;
  .days-container {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-direction: row;
    .css-3eaalp-MuiTypography-root {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
  .override-button {
    border-radius: 4px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    padding: 14px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-shadow: none;
    &:hover {
      box-shadow: none;
      background: #062b6e;
      color: #fff;
    }
  }
  .headers-containers {
    margin-top: 38px;
    margin-bottom: 14px;
    .table-headers {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 200px;
      //   border: 1px solid;
    }
    .first-item {
      width: 100px;
    }
  }
  .each-slot {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    .day-name {
      width: 100px;
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      //   border: 1px solid;
    }
    .start-time,
    .end-time,
    .time-zone {
      width: 160px;
      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 14px 16px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #f8f9fb;
      }
      fieldset {
        border: none;
      }
    }
    .time-zone {
      margin-left: 42px;
    }
    .to {
      margin: 0 10px;
      color: #333;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .icons {
    color: #0b52d4;
  }
  /* styles.scss */
  .custom-button {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px 24px;
    &:hover {
      background: #062b6e;
      color: #fff;
    }
  }
}
