.login-main {
  position: relative;
  .purple-item {
    background: #d2e1fd;
    width: 30vw;
    height: 100vh;
    position: absolute;
    right: 0;
    z-index: 10;
  }
  .content-page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    inset: 0;
    right: 0;
    display: flex;
    z-index: 11;
    gap: 67px;
    align-items: center;
    justify-content: center;
    .item-main {
      display: flex;
      gap: 67px;
      align-items: center;
      justify-content: center;
      border-radius: 8px; /* Equivalent to 2 in Material-UI border radius */
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      background: #fff;
      padding: 40px;
      padding-right: 80px;
    }
  }
}

.login-container {
  // border: 1px solid red;
  margin: 0px;
  .login-title {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    padding-bottom: 40px;
  }
  .user-email {
    input {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
  }
  .user-pass {
    input {
      color: #4f4f4f;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
    }
  }

  .button-end {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    padding: 11px 0;
    cursor: pointer;
    margin-top: 50px;
  }
  .button-end:hover {
    background: #07368e;
  }
}
