.sub-header-routes {
  display: flex;
  gap: 80px;
  margin-top: 30px;

  .nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.3s;
    gap: 6px;
    color: #9e9e9e;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 4px;

    &.active {
      color: #0b52d4;
      font-weight: 500;
      border-bottom: 2px solid #0b52d4;
    }
  }
}
