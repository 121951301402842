.tooltip {
  position: absolute;
  padding: 10px;
  z-index: 1000;

  border-radius: 6px;
  border: 1px solid #e1e1e1;
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
    2px 2px 4px 0px rgba(79, 79, 79, 0.12);
  border-top: 4px solid #0b52d4;
  max-width: 300px;

  h3 {
    margin: 0;
    padding: 0px;
    padding-bottom: 5px;
    color: var(--Black-High, #212121);
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    text-transform: capitalize;
  }

  p {
    margin: 5px 0px;
    display: flex;
    align-items: center;
    gap: 16px;
    color: #333;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    & > span {
      color: #0b52d4;
    }
  }

  button {
    margin-top: 10px;
    background-color: #062b6e;
    color: var(--White-High, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 11px 24px;
    border: none;
    outline: none;
    border-radius: 6px;

    &:hover {
      background-color: #0056b3;
    }
  }
}
