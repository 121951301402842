.video-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 50px;

  .video-item {
    cursor: pointer;
    // margin: 10px;
    border: 1px solid #ccc;
    padding: 20px;
    // width: 80%;
    width: fit-content;
    max-width: 30%;
    // max-height: 175px;
    height: auto;
    text-align: center;
    background-color: #f9f9f9;
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #f6f8fc;

    .dummy-image {
      max-width: 100%;
      max-height: 80%;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      text-align: left;
      color: #333;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-top: 16px;
    }
    &:hover {
      background-color: #e9e9e9;
      //   transform: translateY(-2px);
    }
  }
}

.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.api-data {
  margin-top: 20px;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  width: 100%;
}
