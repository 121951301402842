.back-to-dashboard {
  cursor: pointer;
  margin-top: 44px;
  width: fit-content;

  .back-to-dashboard-content {
    display: flex;
    align-items: center;
    gap: 10px;
    .arrow {
      color: #062b6e;
    }
  }

  .back-to-dashboard-text {
    // Add additional styles for the text if needed
    color: var(--Black-High, #212121);
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
