.meeting-description-wrapper {
  background-color: #fff;
  margin-top: 42px;
  .title {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  .content {
    font-family: "Poppins";
    border: 1px solid #e1e1e1;
    border-top: 4px solid #0b52d4;
    border-radius: 6px;
    background: #e5efff;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .info {
      .heading {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        h5 {
          color: var(--Black-High, #212121);
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */
          margin: 0px;
          padding: 0px;
          text-transform: capitalize;
        }
        .flag {
          display: flex;
          align-items: center;
          gap: 6px;
          margin-left: 20px;
          color: #d24d2c;
          text-align: center;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }

      .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 16px;
        color: #333;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        .icon {
          color: #0b52d4;
          background-color: inherit;
        }
        .text2 {
          color: #6a6868;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
      .flag-reason {
        .title {
          color: #d24d2c;
          text-align: center;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .reason {
          color: #333;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          .round {
            font-weight: 500;
          }
        }
      }
    }

    .actions {
      margin-top: 20px;
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 36px;

      & > button {
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        outline: none;

        color: var(--White-High, #fff);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;

        /* font/button/large/sentence */
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
      }
      & > button:nth-child(1) {
        background: #062b6e;
      }
      & > button:nth-child(2) {
        background: #d24d2c;
      }
      .accept-button {
        background: #0bb762 !important;
        color: #fff !important;
      }
    }
  }
}
.decline-meeting-dialog {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 450px;
  }
  .css-hz1bth-MuiDialog-container {
    height: fit-content;
  }
  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .title2 {
    margin: 0;
    padding: 0;
    color: #2f2f2f;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .title3 {
    margin: 0;
    padding: 0;
    color: #333;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
  }
  .text-area {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    width: 100%;
    resize: none;
    margin-top: 12px;
    padding: 14px;
    min-height: 94px;
    outline: none;
    font-family: "Poppins";
    font-size: 14px;
  }
  .dialog-action-buttons {
    display: flex;
    justify-content: center;
    width: 92%;
    margin: 0px auto;
    margin-bottom: 30px;
    gap: 30px;
    .button1 {
      border-radius: 8px;
      border: 1px solid #777;
      color: #777;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 12px 24px;
    }
    .button2 {
      border-radius: 8px;
      background: #062b6e;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      padding: 12px 24px;
    }
  }
}
