/* CustomHeader.scss */
.custom-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: inherit;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px;

  .date {
    font-size: 1.2em;
    font-weight: bold;
  }

  .weekday {
    font-size: 1em;
    color: #666;
  }
}
