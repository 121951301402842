.details-container {
  display: flex;
  gap: 60px;
  margin-top: 40px;
  .card1 {
    max-width: 377px;
    padding: 38px 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
      2px 2px 4px 0px rgba(79, 79, 79, 0.2);
    .title {
      color: #4f4f4f;
      text-align: center;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 144%; /* 25.92px */
    }
    .join-button {
      margin-top: 36px;
      text-align: center;
      & > button {
        border-radius: 4px;
        background: #062b6e;
        padding: 12px 34px;
        color: var(--White-High, #fff);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        outline: none;
        border: none;
        /* font/button/large/sentence */
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        cursor: pointer;
        & > hover {
        }
      }
    }
  }
  .card {
    padding: 38px 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
      2px 2px 4px 0px rgba(79, 79, 79, 0.2);
    flex: 1;
    .card-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .card-item {
        flex: 1;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #e0e0e0;
        // text-align: center;
        width: 33%;

        &:last-child {
          margin-right: 0;
          border: none;
        }

        .label {
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 144%; /* 25.92px */
        }

        .value {
          color: var(--Black-High, #212121);
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 120% */
          margin-top: 14px;
        }
      }
    }
    .section2 {
      margin-top: 40px;
    }

    .download-button {
      border-radius: 4px;
      border: 1px solid #062b6e;
      outline: none;
      padding: 10px 8px;
      color: #062b6e;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      display: flex;
      align-items: center;
      gap: 6px;
      background: #fff;
      cursor: pointer;
      width: fit-content;
    }
  }
}
