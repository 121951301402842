.calendar-container {
  height: 600pt;
  max-width: 95%;
  margin: 0 auto;
  .rbc-month-header {
    // border: 1px solid red;
    background: #fff;
    .rbc-header {
      border-left: 1px solid #e0e0e0;
      background: inherit;
      padding: 0px;
      // min-height: 135px;
      .custom-header {
        background: inherit;
        // padding: 0px;
      }
    }
  }
  .rbc-today {
    // border: 1px solid red;
    .rbc-timeslot-group {
      background: #fff;
    }
  }
  .rbc-event {
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    background: #0b52d4; // Default blue background
  }
  /* Styling for red background when any child has declined class */
  .rbc-event.has ~ declined {
    background: #ff4d4d; /* Red background */
  }
}
.rbc-time-view {
  .rbc-time-gutter {
    min-width: 135px; /* Minimum width */
    text-align: center; /* Horizontal alignment */
    border-right: 1px solid #e0e0e0;
  }

  .rbc-time-slot {
    min-height: 80px; /* Minimum height */
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
  }

  .rbc-timeslot-group {
    min-height: 8; /* Minimum height */
  }

  .rbc-day-slot .rbc-time-slot {
    border-bottom: 1px solid #e0e0e0;
  }
  .rbc-time-header-gutter {
    min-width: 135px !important;
  }
  .rbc-time-header-content {
    div {
      border: none !important;
    }
    & > div:nth-child(2) {
      display: none;
    }

    .rbc-time-header-cell {
      min-width: 135px; /* Ensure same width as time gutter */
      text-align: center; /* Horizontal alignment */
      display: flex;
      align-items: center; /* Vertical alignment */
      justify-content: center; /* Horizontal alignment */
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      .rbc-header {
        border: 1px solid #e0e0e0 !important;
        border-bottom: none !important;
      }
    }
  }
}

.event {
  cursor: pointer;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;

  button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background-color: #007bff;
      color: white;
    }

    &:hover {
      background-color: #d0d0d0;
    }
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin: 0 10px;
    font-weight: bold;
  }
}
