.competency-card {
  margin-bottom: 100px;
  flex: 1;
  border-radius: 8px;
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
    2px 2px 4px 0px rgba(79, 79, 79, 0.2);

  .competency-tile {
    border-bottom: 1px solid #d9d9d9;
    padding: 18px 30px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    .competency-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .competency-title {
        display: flex;
        align-items: center;
        color: #333;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */

        .arrow {
          margin-right: 8px;
          transition: transform 0.3s;
          margin-top: 4px;
          color: #0b52d4;

          &.open {
            transform: rotate(90deg);
            margin-top: 0px;
          }
        }
      }

      .stars {
        display: flex;
        gap: 14px;
      }
    }

    .competency-description {
      .each-desc {
        color: #333;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        display: flex;
        padding-top: 16px;
        .index {
          height: 100%;
        }
        .desc {
          margin-left: 10px;
        }
        &:first-child {
          border-top: 1px solid #d9d9d9;
          margin-top: 16px;
        }
      }
      .competency-comment {
        width: 100%;
        .comment {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          min-height: 150px;
          resize: none;
          outline: none;
          border-radius: 8px;
          background: #fff;
          padding: 20px;
          color: #333;
          font-family: "Poppins";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 16px;
        }
      }
    }
  }

  .total-score {
    margin-top: -2px;
    padding: 20px 30px;
    background: #ccdeff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .avg {
      color: #212121;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 4px;
    }
    .score {
      color: #062b6e;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 4px;
      .score-star {
        & > img {
          margin-top: 4px;
        }
      }
    }
  }
}
