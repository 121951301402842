.comment-description-wrapper {
  width: 35%;

  .comment {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 150px;
    resize: none;
    border: none;
    outline: none;
    border-radius: 8px;
    background: #fff;
    box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
      2px 2px 4px 0px rgba(79, 79, 79, 0.2);
    padding: 20px;
    color: #333;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description-container {
    margin-top: 40px;
    .know-more {
      padding: 20px;
      border-radius: 8px;
      background: #fff;
      box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.12),
        2px 2px 4px 0px rgba(79, 79, 79, 0.2);

      .know-more-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .know-more-text {
          font-size: 16px;
          font-weight: bold;

          .star-icon {
            margin-left: 8px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }

        .arrow-icon {
          svg {
            font-size: 32px;
            color: #0b52d4;
          }
          transition: transform 0.3s;
        }
        .open {
          transform: rotate(180deg);
        }
      }

      .start-details {
        margin-top: 16px;

        .each-star {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .star-number {
            margin-right: 8px;
            width: 8px;
            color: #767676;
          }

          .star-image {
            margin-right: 8px;
            img {
              width: 20px;
              height: 20px;
            }
          }

          .star-description {
            //  desc
          }
        }
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    gap: 40px;
    margin-top: 40px;

    .button {
      padding: 12px 30px;
      font-size: 16px;
      border: none;
      outline: none;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: background-color 0.3s;

      &.go-back {
        background-color: #fff;
        color: #777;
        border: 1px solid #777;

        // &:hover {
        //   background-color: #dcdcdc39;
        //   color: #fff;
        // }
      }

      &.submit-result {
        background-color: #062b6e;
        color: #fff;
        font-weight: 600;
        line-height: 140%;

        // &:hover {
        //   background-color: #005cbf;
        // }
      }
    }
  }
}
