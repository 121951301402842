.custom-time-gutter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-bottom: none;

  .timezone {
    color: #767676;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
  }
}
