.candidate-header {
  background: #fff;
  top: 0px;
  width: 100vw;
  .header-wrapper {
    background: inherit;
    box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.1) inset;
    color: #000;
    .logo {
      cursor: pointer;
    }
    .candidate-support {
      color: #4f4f4f;
      text-align: right;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      display: flex;
      gap: 4px;
      align-items: center;
      & > span {
        & > img {
          margin-top: 6px;
        }
      }
    }
    .notification-icon {
      color: grey;
    }
    .account-icon {
      color: grey;
    }
  }
}
.css-hyum1k-MuiToolbar-root {
  // border: 1px solid;
  padding-left: 0px !important;
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root,
  .css-i6s8oy {
    // border: 1px solid;
    padding-left: 0px !important;
  }
}
