.container {
  margin-top: 40px;
  max-width: 95%;
  margin-left: 3vw;
  .title {
    color: var(--Black-High, #212121);
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .searchField {
    margin-top: 50px;
    display: flex;
    border-radius: 4px;
    background: #fff;
    max-width: 450px;
    box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.08),
      2px 2px 4px 0px rgba(79, 79, 79, 0.08);
    cursor: pointer;
    align-items: center;
    gap: 12px;
    .icon {
      padding: 10px 16px;
      padding-right: 0px;
    }
    .inputName {
      width: calc(100%);
      & > input {
        padding: 10px 16px;
        width: 100%;
        color: #000;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        border: none;
        outline: none;
        padding-left: 0px;
      }
    }
  }

  .tableContainer {
    margin-top: 30px;
    box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.08),
      2px 2px 4px 0px rgba(79, 79, 79, 0.08);
    .tableHeader {
      background-color: #e3e9f5;
      th {
        padding: 20px 30px;
        color: var(--Secondary-Black, #616161);
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    .tableBody {
      .tableRow:nth-child(even) {
        background-color: #f6f6f6;
      }
      .tableRow {
        border: none;
        .tableCell {
          padding: 20px 30px;
          color: var(--Secondary-Black, #616161);
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        .projectURL {
          cursor: pointer;
          &:hover {
            text-decoration: underline;
            color: #062b6e;
          }
        }
      }
    }
  }
}
