.over-write-container {
  .subtitle1 {
    color: #212121;
    font-family: "Poppins";

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 0;
  }
  .subtitle {
    color: #4f4f4f;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
    padding-top: 0;
  }
  .headers-containers {
    margin-top: 38px;
    margin-bottom: 14px;
    margin-left: 10px;
    .table-headers {
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 200px;
      //   border: 1px solid;
    }
    .first-item {
      width: 100px;
    }
  }
  .each-slot {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    .day-name {
      width: 100px;
      color: #212121;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 10px;
      //   border: 1px solid;
    }
    .start-time,
    .end-time,
    .time-zone {
      width: 160px;
      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 14px 16px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        background: #f8f9fb;
      }
      fieldset {
        border: none;
      }
    }
    .time-zone {
      margin-left: 42px;
    }
    .to {
      margin: 0 10px;
      color: #333;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .icons {
    color: #0b52d4;
  }
  /* Add these styles to OverWrite.scss */

  .rbc-btn-group {
    & > button:nth-child(1) {
      display: none; /* Hide the today button */
    }
  }

  /* Add this CSS to OverWrite.scss */

  .rbc-day-bg.highlighted {
    background-color: blue;
  }

  .rbc-day-bg.highlighted .rbc-button-link,
  .rbc-date-cell .rbc-button-link.selected {
    color: #fff !important;
  }
  .highlighted {
    background-color: blue !important;
    color: #fff !important;
  }

  /* Override rbc-today to remove today's date highlight */
  .rbc-today {
    // background-color: transparent !important;
    // color: inherit !important;
  }

  /* Ensure selected dates are highlighted */
  .rbc-day-bg.selected,
  .rbc-date-cell.selected {
    background-color: blue !important;
    color: #fff !important;
  }
  .action-buttons {
    justify-content: start;
    gap: 36px;
    padding-left: 16px;
    .button1 {
      border-radius: 8px;
      border: 1px solid #777;
      color: #777;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 9px 20px;
    }
    .button2 {
      border-radius: 8px;
      background: #062b6e;
      border: none;
      color: #777;
      font-family: "Poppins";
      color: #fff;
      font-family: "Poppins";
      padding: 10px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
