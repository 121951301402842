.custom-availability-container {
  padding: 30px;
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 0.4);
  background: #fff;
  box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.04),
    2px 2px 4px 0px rgba(79, 79, 79, 0.04);
  position: relative;
  .custom-button-date {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 18px;
    &:hover {
      box-shadow: none;
      background: #062b6e;
      color: #fff;
    }
  }

  .each-date {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 30px;
    margin-bottom: 40px;
    .headers-containers {
      margin-top: 38px;
      margin-bottom: 14px;
      .table-headers {
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 200px;
        //   border: 1px solid;
      }
      .first-item {
        width: 224px;
      }
    }

    .each-slot {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 24px;
      .day-name {
        width: 100px;
        color: #212121;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        //   border: 1px solid;
      }
      .date-input {
        width: 184px;
        margin-right: 40px;
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
        .MuiOutlinedInput-input {
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          background: #f8f9fb;
          padding: 14px 16px;
        }

        fieldset {
          border: none;
        }
      }
      .start-time,
      .end-time,
      .time-zone {
        width: 160px;
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 14px 16px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          background: #f8f9fb;
        }
        fieldset {
          border: none;
        }
      }
      .time-zone {
        margin-left: 42px;
      }
      .to {
        margin: 0 10px;
        color: #333;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .delete-all {
        border-radius: 4px;
        border: 1.2px solid #062b6e;
        padding: 10px 16px 9px 16px;
        color: #062b6e;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        background: #fff;
        margin-left: 30px;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .icons {
    color: #0b52d4;
  }
  .custom-button {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 14px 24px;
    &:hover {
      box-shadow: none;
      background: #062b6e;
      color: #fff;
    }
  }
}
