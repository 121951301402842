.reportCardContainer {
  margin-top: 62px;
  margin-bottom: 50px;
  .title {
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
  }
  .reportCard {
    border: 1px solid #ddd;
    border-radius: 5px;
    // padding: 20px;
    background-color: #fff;
    margin: auto;
    margin-top: 16px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background: #fff;
    box-shadow: 2px 2px 4px 0px rgba(33, 33, 33, 0.12);
    & > div:nth-child(1) {
      border-bottom: 1px dashed rgba(11, 82, 212, 0.5);
    }

    .round {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .details {
        flex: 1;

        h3 {
          margin: 0;
          padding: 0;
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          & > span {
            color: #212121;
            font-family: "Poppins";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 28px */
          }
        }

        .score {
          color: #4f4f4f;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          // margin: 5px 0;
          margin: 0px;
          padding: 0px;
          margin-top: 16px;
          & > strong {
            color: #062b6e;
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
          }
        }
      }

      .actions {
        display: flex;
        gap: 10px;
        flex-direction: column;

        .button {
          background-color: #fff;

          color: #0056b3;
          padding: 10px 15px;
          border-radius: 4px;
          border: 1px solid #062b6e;
          cursor: pointer;
          font-size: 0.9em;
          display: flex;
          align-items: center;
          text-align: center;
          gap: 4px;
          justify-content: center;
          color: #062b6e;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */

          &:hover {
            background-color: #0056b3;
            color: #fff;
          }
        }
      }
    }
  }
}
