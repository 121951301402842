.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  background: #fff !important;
  width: 100%;
  margin: 0 auto;
  margin-top: 67px;
  margin-bottom: 36px;
  button {
    border: none;
    outline: none;
  }

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin: 0 10px;
    font-weight: bold;
  }
  .navigator {
    gap: 44px;
    & > button {
      background: #fff;
      border: none;
      outline: none;
      font-size: 32px;
      color: #0b52d4;
      font-weight: 500;
      &:hover {
        background: none !important;
      }
    }
    & > span {
      color: #333;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
    }
  }
  .todayButton {
    border-radius: 40px;
    background: #ccdeff;
    padding: 13px 30px;
    color: #8d8d8d;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    &:hover {
      background-color: #598eef;
      color: #fff;
    }
  }
  .buttonControls {
    padding: 6px 2px;
    border-radius: 40px;
    background: #ccdeff;
    & > button {
      background-color: inherit;
      color: #9e9e9e;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      &:hover {
        background: none !important;
      }
    }
    .active {
      border-radius: 40px;
      background: #0b52d4;
      padding: 7px 32px;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      color: #fff;
    }
  }
}
