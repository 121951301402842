.assessment-container {
  max-width: 95%;
  margin: 0 auto;
  margin-left: 3vw;

  .results-container {
    display: flex;
    gap: 30px;
    margin-top: 40px;
  }
}

.modal-last {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 40px 30px;
  position: relative;
  text-align: center;
  outline: none;
  border: none;
  .title {
    margin: 0;
    padding: 0;
    color: #212121;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .title2 {
    color: #4f4f4f;
    font-family: "Poppins";
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nextButton {
    border-radius: 8px;
    background: #062b6e;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    &:hover {
      background: #062b6e;
    }
  }
  .cancel {
    border-radius: 8px;
    border: 1px solid #777;
    background: #fff;
    color: #777;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 24px;
    margin-top: 35px;
    margin-right: 36px;
  }
}
