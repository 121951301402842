.dashboard-container {
  margin-top: 32px;
  padding: 0 16px; // Padding to align content
  max-width: 100% !important;
  .row-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header {
      margin: 0;
      padding: 0;
      color: #212121;
      font-family: "Poppins";
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 42px */
    }
    .availability {
      border: none;
      outline: none;
      display: flex;
      background: none;
      border-radius: 4px;
      border: 1.2px solid #062b6e;
      padding: 12px 24px;
      gap: 12px;
      color: #062b6e;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      cursor: pointer;
    }
  }
  .header2 {
    margin: 0;
    padding: 0;
    color: #7b7777;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    margin-top: 8px;
  }
  .cards-container {
    margin-top: 0px;
    .dashboard-card {
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 8px;
      background: #fff;
      box-shadow: -2px -2px 4px 0px rgba(79, 79, 79, 0.08),
        2px 2px 4px 0px rgba(79, 79, 79, 0.08);
      height: 100%; // Ensure cards take up the same height
      height: 120px;
    }

    .dashboard-avatar {
      background-color: #e3f2fd;
      color: #1e88e5;
      margin-right: 16px;
    }

    .dashboard-card-content {
      flex-grow: 1;
      .label {
        color: #888;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
      .value {
        color: #212121;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .dashboard-container {
    padding: 0 8px;

    .dashboard-card {
      flex-direction: column;
      align-items: flex-start;

      .dashboard-avatar {
        margin-bottom: 8px;
      }
    }
  }
}
