.event {
  cursor: pointer;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  border-radius: 6px;
  background: inherit;
}
.event.declined {
  color: #d4d4d4;
}
.event-content {
  display: flex;
  flex-direction: column;
}

.event-time {
  font-size: 0.8em;
  color: gray;
}

.event-title {
  font-size: 1em;
  font-weight: bold;
}
